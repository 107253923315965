<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Códigos ARL</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="2"
            >
              <b-form-group
                label-for="number"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.number') }}</span>
                <b-form-input
                  id="number"
                  v-model="medical_order"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
            >
              <b-form-group
                label-for="date"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.generation_date') }}</span>
                <b-form-input
                  id="date"
                  v-model="dateParsed"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
            >
              <b-form-group
                label-for="proposedDate"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.proposed_date') }}</span>
                <b-form-input
                  id="date"
                  v-model="proposedDateParsed"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <b-form-group
                label-for="arl_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.arl')"
                >
                  <b-form-input
                    id="arl_name"
                    v-model="elementData.arl_name"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <b-form-group
                label-for="medical_order_status"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl_code_order_status') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.medical_order_status')"
                >
                  <b-form-input
                    id="medical_order_status"
                    v-model="elementData.medical_order_status.value"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>

            <b-col
              cols="2"
            >
              <b-form-group
                label-for="case_number"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.case_number') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.case_number')"
                >
                  <b-form-input
                    id="case_number"
                    v-model="elementData.case_number"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
            >
              <b-form-group
                label-for="internal_code"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.internal_code') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.internal_code')"
                >
                  <b-form-input
                    id="internal_code"
                    v-model="elementData.internal_code"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="patient_identity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_identity') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.patient_identity')"
                >
                  <b-form-input
                    id="patient_identity"
                    v-model="elementData.patient_identity"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="patient_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_name') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.patient_name')"
                >
                  <b-form-input
                    id="patient_name"
                    v-model="elementData.patient_name"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>

            <b-col
              cols="4"
            >
              <b-form-group
                label-for="service_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.service_type') }}</span>
                <b-form-input
                  id="service_type"
                  v-model="elementData.service_type.value"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="follow_type_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.follow_type') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.follow_type')"
                >
                  <b-form-input
                    id="follow_type_name"
                    v-model="elementData.follow_type_name"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="elementData.next_consult"
              cols="4"
            >
              <b-form-group
                label-for="next_consult"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.next_consult') }}</span>
                <b-form-input
                  id="next_consult"
                  v-model="elementData.next_consult.value"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <groups-tab
              :element-data="elementData"
              :service-bundle-list="serviceBundleList"
            />
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'
import router from '@/router'
import GroupsTab from './EditGroups.vue'

export default {
  components: {
    GroupsTab, BFormTextarea, DatePicker, BFormCheckbox, BCard, BButton, BRow, BCol, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    serviceBundleList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: 'arl_codes',
      vigence_date_render: '',
      required,
      dateParsed: undefined,
      proposedDateParsed: undefined,
      medical_order: '',
    }
  },
  watch: {
    'elementData.authorization_date': function (newValue) {
      this.update_vigence_date()
    },
  },
  mounted() {
    let prefix = 'LAB-'

    switch (this.elementData.service_type.key) {
      case 'VACCINATION':
        prefix = 'VAC-'
        this.process_dosage_dates()
        break
      case 'MEDICINES':
        prefix = 'MED-'
        this.elementData.proposed_date = this.elementData.date
        break
      case 'FOLLOWUPS':
        prefix = 'CON-'
        break
    }

    this.medical_order = prefix + String(this.elementData.id).padStart(6, '0')
    this.update_vigence_date()
    this.dateParsed = this.formatDate(this.elementData.date)
    this.proposedDateParsed = this.formatDate(this.elementData.proposed_date)
  },
  methods: {
    router() {
      return router
    },
    formatDate(value) {
      return moment(value, 'DD/MM/YYYY').isValid() ? moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
    },
    update_vigence_date() {
      let service_vigency = 0
      if (this.elementData.service_bundle_id && this.serviceBundleList && this.serviceBundleList.length > 0) {
        service_vigency = this.serviceBundleList.find(element => element.id == this.elementData.service_bundle_id).validity
      }
      let authorization_date = moment()
      if (this.elementData.authorization_date) {
        authorization_date = moment(this.elementData.authorization_date)
      }
      this.elementData.vigence_date = authorization_date.add(service_vigency, 'days').toDate()
      this.vigence_date_render = moment(this.elementData.vigence_date).format('DD/MM/YYYY')
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if ('service_type' in this.elementData) {
            delete this.elementData.service_type
          }
          this.$parent.save(this.elementData)
        }
      })
    },
    process_dosage_dates() {
      const first_dosage = this.elementData.bundles[0]?.services[0]?.first_dosage
      const second_dosage = this.elementData.bundles[0]?.services[0]?.second_dosage
      const third_dosage = this.elementData.bundles[0]?.services[0]?.third_dosage
      const fourth_dosage = this.elementData.bundles[0]?.services[0]?.fourth_dosage
      const antibody_dosage = this.elementData.bundles[0]?.services[0]?.antibody_dosage

      this.elementData.proposed_date = first_dosage || second_dosage || third_dosage || fourth_dosage || antibody_dosage
    },
  },
}
</script>

<style>

</style>
